import React from 'react';
import styled from 'styled-components';

import { List } from './common';
import Company from './Company';
import Developers from './Developers';
import Logo from './Logo';
import Products from './Products';
import Resources from './Resources';

/**
 * Component container.
 */
const Container = styled.footer`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

/**
 * Desktop Footer.
 *
 * @returns {React.Component} Footer.
 */
const DesktopFooter = () => (
  <Container>
    <List>
      <Logo />
    </List>

    <List>
      <Products />
    </List>

    <List>
      <Developers />
    </List>

    <List>
      <Company />
    </List>

    <List>
      <Resources />
    </List>
  </Container>
);

export default DesktopFooter;
