import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import colours from '_theming/colours';

/**
 * Custom Anchor component.
 */
const CustomAnchor = styled(OutboundLink)`
  display: block;

  font-family: inherit;
  color: ${(props) => props.textcolor || colours.daintree};
  background-color: ${(props) => props.bgcolor || colours.white};
  text-decoration: none;
  text-align: center;

  border-radius: 4px;
  padding: 12px 14px;

  box-shadow: 0 2px 4px 0 rgba(0, 43, 61, 0.2);
`;

/**
 * External Link Button.
 *
 * @param {object} props Component props.
 * @param {string} props.className CSS class name.
 * @param {string} props.href Link destination.
 * @param {string} props.bgcolor Background colour.
 * @param {string} props.textcolor Text colour.
 * @param {string} props.target Where to open linked document.
 * @param {React.Component} props.children Child nodes to be rendered.
 * @returns {React.Component} External Link Button.
 */
const ExternalLinkButton = ({ className, href, bgcolor, textcolor, children, target }) => (
  <CustomAnchor
    className={className}
    href={href}
    bgcolor={bgcolor}
    textcolor={textcolor}
    target={target}
  >
    {children}
  </CustomAnchor>
);

ExternalLinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ExternalLinkButton.defaultProps = {
  bgcolor: colours.daintree,
  target: '_self',
};

export default ExternalLinkButton;
