const responsiveDesignBreakpoints = {
  mobile: {
    min: 0,
    max: 799,
  },
  tablet: {
    min: 800,
    max: 1024,
  },
  desktop: {
    min: 1025,
  },
};

export default responsiveDesignBreakpoints;
