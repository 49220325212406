import { useEffect, useState } from 'react';

/**
 * Hook to forcefully use client-side rendering.
 */
const useIsClient = () => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
};

export default useIsClient;
