import React from 'react';

import InternalLink from '_components/InternalLink';

import { Item } from './common';

/**
 * Products links.
 *
 * @returns {React.Component} Links.
 */
const Products = () => (
  <React.Fragment>
    Products
    <Item>
      <InternalLink to="/payments/">Payments</InternalLink>
    </Item>
    <Item>
      <InternalLink to="/billing/">Billing</InternalLink>
    </Item>
    <Item>
      <InternalLink to="/services/">Merchant Services</InternalLink>
    </Item>
    <Item>
      <InternalLink to="/pricing/">Pricing</InternalLink>
    </Item>
  </React.Fragment>
);

export default Products;
