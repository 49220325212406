import React from 'react';

import InternalLink from '_components/InternalLink';

import { Item } from './common';

/**
 * Resources links.
 *
 * @returns {React.Component} Links.
 */
const Resources = () => (
  <React.Fragment>
    Resources
    <Item>
      <InternalLink to="/privacy/">Privacy Policy</InternalLink>
    </Item>
    <Item>
      <InternalLink to="/terms/">Terms of Service</InternalLink>
    </Item>
  </React.Fragment>
);

export default Resources;
