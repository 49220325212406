import React from 'react';
import styled from 'styled-components';

import { List } from './common';
import Company from './Company';
import Developers from './Developers';
import Logo from './Logo';
import Products from './Products';
import Resources from './Resources';

/**
 * Component container.
 */
const Container = styled.footer`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

/**
 * Divider.
 */
const Divider = styled.div`
  margin-bottom: 25px;
`;

/**
 * Mobile footer.
 *
 * @returns {React.Component} Mobile footer.
 */
const MobileFooter = () => (
  <Container>
    <Divider>
      <Logo />
    </Divider>

    <List>
      <Divider>
        <Products />
      </Divider>

      <Divider>
        <Developers />
      </Divider>

      <Divider>
        <Company />
      </Divider>

      <Resources />
    </List>
  </Container>
);

export default MobileFooter;
