import React from 'react';

import useIsClient from '_hooks/useIsClient';
import useResponsive from '_hooks/useResponsive';

import DesktopFooter from './components/DesktopFooter';
import MobileFooter from './components/MobileFooter';

/**
 * Footer.
 *
 * @returns {React.Component} Footer.
 */
const Footer = () => {
  const isClient = useIsClient();
  const { isDesktop } = useResponsive();

  if (!isClient) {
    return null;
  }

  return <React.Fragment>{isDesktop ? <DesktopFooter /> : <MobileFooter />}</React.Fragment>;
};

export default Footer;
