import styled from 'styled-components';

import colours from '_theming/colours';

/**
 * List.
 */
const List = styled.ul`
  font-family: 'Big Caslon Medium', serif;
  font-size: 1rem;
  color: ${colours.white};

  list-style-type: none;

  margin: 0;
  padding: 0;
`;

/**
 * List item.
 */
const Item = styled.li`
  font-family: 'Acumin Pro Extra Light', sans-serif;
  font-size: 0.875rem;
  color: ${colours.white};

  margin-top: 8px;
`;

export { List, Item };
