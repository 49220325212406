import React from 'react';
import styled from 'styled-components';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import colours from '_theming/colours';
import InternalLink from '_components/InternalLink';
import PricematepayLogo from '_images/svg/logos/Pricematepay/LightBlue.svg';

import { SignInLinkButton, SignUpLinkButton } from './ExternalLinkButtons';
import PopoutMenu from './PopoutMenu';

/**
 * Component container.
 */
const Container = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  background-color: ${colours.daintree};
  color: ${colours.white};

  padding: 21px 32px;
`;

/**
 * Container for the logo.
 */
const ContainerLogo = styled.div`
  svg {
    width: auto;
    height: 50px;
  }
`;

/**
 * Container for the navigation bar items.
 */
const List = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  font-family: 'Big Caslon Medium', serif;
  font-size: 1rem;

  margin: 0;
  padding: 0;

  cursor: pointer;
`;

/**
 * Container for the navigation bar item.
 */
const ListItem = styled.li`
  list-style-type: none;

  &:not(:last-child) {
    margin-right: 40px;
  }
`;

/**
 * Container for the Sign In/Up navigation bar items.
 */
const ListItemSignInUp = styled(ListItem)`
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

/**
 * Button navigation item.
 */
const NavItemButton = styled.button`
  background: none;
  border: none;
  padding: 0;

  font-family: inherit;
  font-size: inherit;
  color: inherit;

  cursor: pointer;
`;

// Popover configuration.
const options = {
  trigger: ['hover'],
  closeOnTriggerHidden: true,
  interactive: true,
  delayHide: 150,
};
/**
 * Desktop Navigation bar.
 *
 * @returns {React.Component} Navigation bar.
 */
const DesktopNavigationBar = () => {
  // Controls the visibility of the Products submenu.
  const {
    getArrowProps: getArrowPropsProducts,
    getTooltipProps: getTooltipPropsProducts,
    setTooltipRef: setTooltipRefProducts,
    setTriggerRef: setTriggerRefProducts,
    visible: visibleProducts,
  } = usePopperTooltip(options);

  // Controls the visibility of the Developers submenu.
  const {
    getArrowProps: getArrowPropsDevelopers,
    getTooltipProps: getTooltipPropsDevelopers,
    setTooltipRef: setTooltipRefDevelopers,
    setTriggerRef: setTriggerRefDevelopers,
    visible: visibleDevelopers,
  } = usePopperTooltip(options);

  // Controls the visibility of the Company submenu.
  const {
    getArrowProps: getArrowPropsCompany,
    getTooltipProps: getTooltipPropsCompany,
    setTooltipRef: setTooltipRefCompany,
    setTriggerRef: setTriggerRefCompany,
    visible: visibleCompany,
  } = usePopperTooltip(options);

  return (
    <React.Fragment>
      {visibleProducts && (
        <PopoutMenu
          ref={setTooltipRefProducts}
          getTooltipProps={getTooltipPropsProducts}
          getArrowProps={getArrowPropsProducts}
          items={[
            { to: '/payments/', label: 'Payments' },
            { to: '/billing/', label: 'Billing' },
            { to: '/services/', label: 'Merchant Services' },
          ]}
        />
      )}

      {visibleDevelopers && (
        <PopoutMenu
          ref={setTooltipRefDevelopers}
          getTooltipProps={getTooltipPropsDevelopers}
          getArrowProps={getArrowPropsDevelopers}
          items={[
            {
              href: 'https://gateway.pricematepay.com/docs',
              label: 'Documentation',
            },
            {
              href: 'https://gateway.pricematepay.com/docs/api',
              label: 'API Reference',
            },
            { href: 'https://status.pricematepay.com/', label: 'API Status' },
          ]}
        />
      )}

      {visibleCompany && (
        <PopoutMenu
          ref={setTooltipRefCompany}
          getTooltipProps={getTooltipPropsCompany}
          getArrowProps={getArrowPropsCompany}
          items={[
            { to: '/about/', label: 'About Us' },
            { to: '/contact/', label: 'Contact Sales' },
          ]}
        />
      )}

      <Container>
        <ContainerLogo>
          <InternalLink to="/">
            <PricematepayLogo />
          </InternalLink>
        </ContainerLogo>

        <List>
          <ListItem>
            <NavItemButton type="button" ref={setTriggerRefProducts}>
              Products
            </NavItemButton>
          </ListItem>
          <ListItem>
            <NavItemButton type="button" ref={setTriggerRefDevelopers}>
              Developers
            </NavItemButton>
          </ListItem>
          <ListItem>
            <InternalLink to="/pricing/">Pricing</InternalLink>
          </ListItem>
          <ListItem>
            <NavItemButton type="button" ref={setTriggerRefCompany}>
              Company
            </NavItemButton>
          </ListItem>
        </List>

        <List>
          <ListItemSignInUp>
            <SignInLinkButton />
          </ListItemSignInUp>

          <ListItemSignInUp>
            <SignUpLinkButton />
          </ListItemSignInUp>
        </List>
      </Container>
    </React.Fragment>
  );
};

export default DesktopNavigationBar;
