import React from 'react';
import PropTypes from 'prop-types';

/**
 * Curved background.
 *
 * @param {object} props Component props.
 * @param {string} props.preserveAspectRatio Value of the preserveAspectRatio
 *                                           property.
 * @param {string} props.fillColor Fill color.
 * @returns {React.Component} Curved background.
 */
const Curved = ({ preserveAspectRatio, fillColor }) => (
  <svg preserveAspectRatio={preserveAspectRatio} viewBox="3 -130.144 1920 543.388">
    <g transform="translate(0 -271)">
      <path
        style={{ fill: fillColor }}
        d="M3 674.514s14.515-7.023 97.044 5.269c82.53 12.291 107.112-3.513 124.781-14.926 17.67-11.414 75.396-53.117 117.978-92.186 42.58-39.07 76.382-79.458 152.327-97.016 0 0 17.56-7.464 89.114-7.902h334.507s55.458 1.024 97.6-10.097c0 0 56.482-14.047 100.82-39.509 44.337-25.46 180.862-114.574 267.78-157.156 86.919-42.582 295.437-161.838 435.911-99.795 0 0 56.484 21.656 102.138 75.505v-95.845H3z"
      ></path>
    </g>
  </svg>
);

Curved.propTypes = {
  preserveAspectRatio: PropTypes.string,
  fillColor: PropTypes.string,
};

Curved.defaultProps = {
  preserveAspectRatio: 'auto',
  fillColor: '#000',
};

export default Curved;
