import React from 'react';

import ExternalLink from '_components/ExternalLink';

import { Item } from './common';

/**
 * Developers links.
 *
 * @returns {React.Component} Links.
 */
const Developers = () => (
  <React.Fragment>
    Developers
    <Item>
      <ExternalLink href="https://gateway.pricematepay.com/docs" target="_blank">
        Documentation
      </ExternalLink>
    </Item>
    <Item>
      <ExternalLink href="https://gateway.pricematepay.com/docs/api" target="_blank">
        API Reference
      </ExternalLink>
    </Item>
    <Item>
      <ExternalLink href="https://status.pricematepay.com" target="_blank">
        API Status
      </ExternalLink>
    </Item>
  </React.Fragment>
);

export default Developers;
