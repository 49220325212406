import useViewportDimensions from '_hooks/useViewportDimensions';
import breakpoints from '_util/responsiveDesign/breakpoints';

/**
 * Determines whether or not a Mobile, Tablet, or Desktop device is being used.
 *
 * @returns {object} Which device type is used.
 */
const useResponsive = () => {
  const { width } = useViewportDimensions();

  const isMobile = width >= breakpoints.mobile.min && width <= breakpoints.mobile.max;
  const isTablet = width >= breakpoints.tablet.min && width <= breakpoints.tablet.max;
  const isDesktop = width >= breakpoints.desktop.min;

  return { isMobile, isTablet, isDesktop };
};

export default useResponsive;
