import React from 'react';
import styled from 'styled-components';

import InternalLink from '_components/InternalLink';
import PricematepayLogo from '_images/svg/logos/Pricematepay/LightBlue.svg';

/**
 * Component container.
 */
const Container = styled.div`
  svg {
    width: 200px;
    height: auto;
  }
`;

/**
 * Footer logo.
 *
 * @returns {React.Component} Footer logo.
 */
const Logo = () => (
  <Container>
    <InternalLink to="/">
      <PricematepayLogo />
    </InternalLink>
  </Container>
);

export default Logo;
