import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colours from '_theming/colours';
import ExternalLink from '_components/ExternalLink';
import InternalLink from '_components/InternalLink';

/**
 * Component container.
 */
const Container = styled.ul`
  background-color: ${colours.white};
  color: ${colours.daintree};

  border-radius: 5px;
  max-width: 320px;

  margin: 0;
  padding: 0;

  font-family: 'Acumin Pro Extra Light', sans-serif;
  text-align: center;
`;

/**
 * List item.
 */
const ListItem = styled.li`
  list-style-type: none;
`;

/**
 * Custom external link.
 */
const CustomExternalLink = styled(ExternalLink)`
  display: block;

  color: inherit;
  text-decoration: none;

  padding: 15px 16px;
`;

/**
 * Custom internal link.
 */
const CustomInternalLink = styled(InternalLink)`
  display: block;

  color: inherit;
  text-decoration: none;

  padding: 15px 16px;
`;

/**
 * Popout menu.
 *
 * @param {object} props Component props.
 * @param {function} props.getTooltipProps Merges any passed props with the
 *                                         internal props of the tooltip
 *                                         element.
 * @param {function} props.getArrowProps Merges any passed props with the
 *                                       internal props of the arrow element.
 * @param {array} props.items Array of link items.
 * @param {object} props.items[0] Link item.
 * @param {string} props.items[0].to Internal link destination.
 * @param {string} props.items[0].href External link destination.
 * @param {string} props.items[0].label Link label.
 * @param {function} ref Component reference.
 * @returns {React.Component} Popout menu.
 */
const PopoutMenu = React.forwardRef((props, ref) => (
  <Container ref={ref} {...props.getTooltipProps({ className: 'tooltip-container' })}>
    <React.Fragment>
      <div {...props.getArrowProps({ className: 'tooltip-arrow' })}></div>

      {props.items.map((item) => {
        if (item.to) {
          return (
            <ListItem key={item.to}>
              <CustomInternalLink to={item.to}>{item.label}</CustomInternalLink>
            </ListItem>
          );
        }

        if (item.href) {
          return (
            <ListItem key={item.href}>
              <CustomExternalLink href={item.href} target="_blank">
                {item.label}
              </CustomExternalLink>
            </ListItem>
          );
        }

        return null;
      })}
    </React.Fragment>
  </Container>
));

PopoutMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default PopoutMenu;
