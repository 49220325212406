import React from 'react';
import styled from 'styled-components';

import colours from '_theming/colours';
import ExternalLinkButton from '_components/ExternalLinkButton';

/**
 * External link with sans-serif font.
 */
const CustomExternalLinkButton = styled(ExternalLinkButton)`
  display: inline;
  font-family: 'Acumin Pro Extra Light' !important, sans-serif;
`;

/**
 * Sign In Link Button.
 *
 * @returns {React.Component} Link Button.
 */
const SignInLinkButton = () => (
  <CustomExternalLinkButton
    href="https://gateway.pricematepay.com/login"
    target="_blank"
    bgcolor={colours.daintree}
    textcolor={colours.white}
  >
    Sign In
  </CustomExternalLinkButton>
);

/**
 * Sign Up Link Button.
 *
 * @returns {React.Component} Link Button.
 */
const SignUpLinkButton = () => (
  <CustomExternalLinkButton
    href="https://gateway.pricematepay.com/signup"
    target="_blank"
    bgcolor={colours.redDamask}
    textcolor={colours.daintree}
  >
    Sign Up
  </CustomExternalLinkButton>
);

export { SignInLinkButton, SignUpLinkButton };
