import React from 'react';
import { createGlobalStyle } from 'styled-components';

import acuminProExtraLight from '_fonts/AcuminProExtraLight.otf';
import bigCaslonMedium from '_fonts/BigCaslonMedium.otf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Acumin Pro Extra Light";
    src: url("${acuminProExtraLight}") format("opentype");
  }

  @font-face {
    font-family: "Big Caslon Medium";
    src: url("${bigCaslonMedium}") format("opentype");
  }

  body {
    margin: 0;
    font-family: "Acumin Pro Extra Light", sans-serif;
  }
`;

/**
 * Applies the global stylesheet to a child component.
 *
 * @param {object} props Component props.
 * @param {React.Component} props.children Child nodes to be rendered.
 * @returns {React.Component} Layout which contains global styles.
 */
const Layout = ({ children }) => (
  <React.Fragment>
    <GlobalStyle />
    {children}
  </React.Fragment>
);

export default Layout;
