import { useState, useEffect } from 'react';

/**
 * Gets viewport dimensions.
 *
 * @returns {object} Width and height, in pixels.
 */
const getDimensions = () => {
  // Returns dummy data when building static HTML.
  if (typeof window === 'undefined') {
    return { width: 0, height: 0 };
  }

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

/**
 * React Hook to obtain the viewport dimensions.
 *
 * @returns {object} Width and height, in pixels.
 */
const useViewportDimensions = () => {
  const [dimensions, setDimensions] = useState(getDimensions());

  /**
   * Save dimensions into state.
   */
  const handleResize = () => {
    setDimensions(getDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return dimensions;
};

export default useViewportDimensions;
