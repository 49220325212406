import React from 'react';

import useIsClient from '_hooks/useIsClient';
import useResponsive from '_hooks/useResponsive';

import Desktop from './components/Desktop';
import Mobile from './components/Mobile';

/**
 * Renders the appropriate navigation bar based on the viewport dimensions.
 *
 * @returns {React.Component}  bar.
 */
const NavigationBar = () => {
  const isClient = useIsClient();
  const { isDesktop } = useResponsive();

  if (!isClient) {
    return null;
  }

  return <React.Fragment>{isDesktop ? <Desktop /> : <Mobile />}</React.Fragment>;
};

export default NavigationBar;
