/* stylelint-disable no-descending-specificity */

import React from 'react';
import styled from 'styled-components';

import colours from '_theming/colours';
import ExternalLink from '_components/ExternalLink';
import InternalLink from '_components/InternalLink';

import { SignInLinkButton, SignUpLinkButton } from './ExternalLinkButtons';

/**
 * Component container.
 */
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  color: ${colours.white};

  & > ul:first-child {
    padding: 0;
  }

  li {
    padding: 15px 0;
  }

  min-height: 100vh;
`;

/**
 * List.
 */
const List = styled.ul`
  list-style-type: none;
`;

/**
 * List item which is a category name.
 */
const CategoryItem = styled.li`
  font-family: 'Big Caslon Medium', serif;

  & > ul {
    margin-top: 20px;
  }
`;

/**
 * List item which contains a page name/link.
 */
const PageItem = styled.li`
  font-family: 'Acumin Pro Extra Light', sans-serif;
`;

/**
 * Container for the Sign In/Up buttons.
 */
const ContainerButtons = styled.div`
  margin-top: 20px;

  & > a:not(:last-child) {
    margin-right: 25px;
  }
`;

/**
 * Navigation bar items for the mobile navigation bar.
 *
 * @param {object} props Component props.
 * @param {function} props.handleCloseMenu Closes the menu.
 * @returns {React.Component} Navigation bar items.
 */
const ExpandedNavbarItems = ({ handleCloseMenu }) => (
  <Container>
    <List>
      <CategoryItem>
        Products
        <List>
          <PageItem>
            <InternalLink to="/payments/" onClick={handleCloseMenu}>
              Payments
            </InternalLink>
          </PageItem>
          <PageItem>
            <InternalLink to="/billing/" onClick={handleCloseMenu}>
              Billing
            </InternalLink>
          </PageItem>
          <PageItem>
            <InternalLink to="/services/" onClick={handleCloseMenu}>
              Merchant Services
            </InternalLink>
          </PageItem>
          <PageItem>
            <InternalLink to="/pricing/" onClick={handleCloseMenu}>
              Pricing
            </InternalLink>
          </PageItem>
        </List>
      </CategoryItem>

      <CategoryItem>
        Developer
        <List>
          <PageItem>
            <ExternalLink
              href="https://gateway.pricematepay.com/docs"
              target="_blank"
              onClick={handleCloseMenu}
            >
              Documentation
            </ExternalLink>
          </PageItem>
          <PageItem>
            <ExternalLink
              href="https://gateway.pricematepay.com/docs/api"
              target="_blank"
              onClick={handleCloseMenu}
            >
              API Reference
            </ExternalLink>
          </PageItem>
          <PageItem>
            <ExternalLink
              href="https://status.pricematepay.com/"
              target="_blank"
              onClick={handleCloseMenu}
            >
              API Status
            </ExternalLink>
          </PageItem>
        </List>
      </CategoryItem>

      <CategoryItem>
        Company
        <List>
          <PageItem>
            <InternalLink to="/about/" onClick={handleCloseMenu}>
              About Us
            </InternalLink>
          </PageItem>
          <PageItem>
            <InternalLink to="/contact/" onClick={handleCloseMenu}>
              Contact Us
            </InternalLink>
          </PageItem>
        </List>
      </CategoryItem>
    </List>

    <ContainerButtons>
      <SignInLinkButton />
      <SignUpLinkButton />
    </ContainerButtons>
  </Container>
);

export default ExpandedNavbarItems;
