import styled from 'styled-components';

import colours from '_theming/colours';
import breakpoints from '_util/responsiveDesign/breakpoints';

// Extract responsive design variables.
const { mobile, tablet, desktop } = breakpoints;

/**
 * Page container.
 */
const ContainerPage = styled.main``;

/**
 * Page margin.
 */
const Margin = styled.div`
  margin: 0 auto;
  max-width: 1400px;
`;

/**
 * Container for the navigation bar.
 */
const ContainerNavigation = styled.div`
  background-color: ${colours.daintree};
`;

/**
 * Container for the Landing section.
 */
const ContainerLandingSection = styled.section`
  background-color: ${colours.daintree};
  padding: 50px 0;
`;

/**
 * Container for a section.
 */
const ContainerSection = styled.div`
  background-color: ${(props) => props.bgColor || colours.white};

  @media screen and (max-device-width: ${mobile.max}px) {
    padding: ${(props) => (props.disableTopPadding ? '0' : '50')}px 0 50px 0;
  }

  @media screen and (min-device-width: ${tablet.min}px) and (max-device-width: ${tablet.max}px) {
    padding: ${(props) => (props.disableTopPadding ? '0' : '75')}px 0 75px 0;
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    padding: ${(props) => (props.disableTopPadding ? '0' : '75')}px 0 75px 0;
  }
`;

/**
 * Container for the "Start Getting Paid" section.
 */
const ContainerStartGettingPaid = styled.div`
  background-color: ${(props) => props.bgColor || colours.white};

  @media screen and (max-device-width: ${mobile.max}px) {
    padding: 50px 0 0 0;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    padding: 75px 0 0 0;
  }
`;

/**
 * Container for the curved page divider.
 */
const ContainerSectionDivider = styled.div`
  svg {
    width: 100%;
  }

  @media screen and (max-device-width: ${mobile.max}px) {
    svg {
      height: 100px;
    }
  }

  @media screen and (min-device-width: ${tablet.min}px) and (max-device-width: ${tablet.max}px) {
    svg {
      height: 150px;
    }
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    svg {
      height: 250px;
    }
  }
`;

/**
 * Container for the tiny curved page divider.
 */
const ContainerTinySectionDivider = styled.div`
  svg {
    width: 100%;
  }

  @media screen and (max-device-width: ${mobile.max}px) {
    svg {
      height: 50px;
    }
  }

  @media screen and (min-device-width: ${tablet.min}px) and (max-device-width: ${tablet.max}px) {
    svg {
      height: 100px;
    }
  }

  @media screen and (min-device-width: ${desktop.min}px) {
    svg {
      height: 150px;
    }
  }
`;

/**
 * Container for the footer divider.
 */
const ContainerFooterDivider = styled(ContainerSectionDivider)`
  transform: rotate(180deg);
`;

/**
 * Container for the tiny footer divider.
 */
const ContainerTinyFooterDivider = styled(ContainerTinySectionDivider)`
  transform: rotate(180deg);
`;

/**
 * Container for the footer.
 */
const ContainerFooter = styled.div`
  background-color: ${(props) => props.bgColor || colours.white};
  padding: 50px 20px;
`;

export {
  ContainerPage,
  Margin,
  ContainerNavigation,
  ContainerLandingSection,
  ContainerSection,
  ContainerStartGettingPaid,
  ContainerSectionDivider,
  ContainerFooterDivider,
  ContainerTinySectionDivider,
  ContainerTinyFooterDivider,
  ContainerFooter,
};
