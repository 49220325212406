import styled from 'styled-components';
import { Link } from 'gatsby';

/**
 * Internal link.
 */
const InternalLink = styled(Link)`
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
`;

export default InternalLink;
