import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Close from '_images/svg/icons/Close.svg';
import Hamburger from '_images/svg/icons/Hamburger.svg';
import InternalLink from '_components/InternalLink';
import PricematePayLogo from '_images/svg/logos/Pricematepay/LightBlue.svg';

import ExpandedNavbarItems from './ExpandedNavbarItems';

/**
 * Component container.
 */
const Container = styled.nav`
  display: flex;
  flex-flow: column nowrap;

  padding: 20px;
`;

/**
 * Navigation bar.
 */
const NavigationBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

/**
 * Container for the logo.
 */
const ContainerLogo = styled.div`
  svg {
    width: 100%;
    height: auto;

    max-height: 60px;
  }
`;

/**
 * Container for the trigger button.
 */
const ContainerTriggerButton = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  svg {
    width: 100%;
    height: auto;

    max-width: 50px;
  }
`;

/**
 * Trigger button.
 */
const TriggerButton = styled.button`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  cursor: pointer;
  border: none;
  background-color: transparent;

  text-align: right;
`;

/**
 * Mobile navigation bar.
 *
 * @returns {React.Component} Navigation bar.
 */
const Mobile = () => {
  // Maintains navigation bar content visibility.
  const [isExpanded, setIsExpanded] = useState(false);

  /**
   * Toggles visibility.
   */
  const handleToggleVisibility = () => setIsExpanded(!isExpanded);

  /**
   * Closes content.
   */
  const handleCloseMenu = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Container>
      <NavigationBar>
        <div></div>

        <ContainerLogo>
          <InternalLink to="/" onClick={handleCloseMenu}>
            <PricematePayLogo />
          </InternalLink>
        </ContainerLogo>

        <ContainerTriggerButton>
          <TriggerButton type="button" onClick={handleToggleVisibility}>
            {isExpanded ? <Close /> : <Hamburger />}
          </TriggerButton>
        </ContainerTriggerButton>
      </NavigationBar>

      {isExpanded ? <ExpandedNavbarItems handleCloseMenu={handleCloseMenu} /> : null}
    </Container>
  );
};

export default Mobile;
