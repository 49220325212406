import React from 'react';

import InternalLink from '_components/InternalLink';

import { Item } from './common';

/**
 * Company links.
 *
 * @returns {React.Component} Links.
 */
const Company = () => (
  <React.Fragment>
    Company
    <Item>
      <InternalLink to="/about/">About Us</InternalLink>
    </Item>
    <Item>
      <InternalLink to="/contact/">Contact Sales</InternalLink>
    </Item>
  </React.Fragment>
);

export default Company;
