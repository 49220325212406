const enumeration = Object.freeze({
  black: '#000000',
  daintree: '#002B3D',
  fountainBlue: '#69C1C5',
  jaffa: '#F27345',
  keppel: '#3EB39D',
  lightningYellow: '#FCB915',
  linkWater: '#E1EBF7',
  linkWaterLight: '#F5F8FD',
  redDamask: '#DB7048',
  saffron: '#F7BD38',
  sail: '#B3E0F7',
  tropicalBlue: '#B5DDF5',
  white: '#FFFFFF',
});

export default enumeration;
