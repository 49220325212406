import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

/**
 * External link.
 */
const ExternalLink = styled(OutboundLink)`
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
`;

export default ExternalLink;
